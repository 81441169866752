<template>
  <div :class="chipClass"
       class="chip o-button o-button--hover"
  >
    <span class="chip__status">
      <span :class="checkCircleClass"
            class="check-circle"
      />
    </span>
    <span :class="chipValueClass"
          class="chip__value"
    >
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    value: {
      type: String,
      default: '',
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkCircleClass() {
      return {
        'check-circle--selected': this.enabled,
      };
    },
    chipClass() {
      return {
        'chip--large': this.large,
      };
    },
    chipValueClass() {
      return {
        'chip__value--large': this.large,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.chip
  display inline-flex
  padding 2px 8px
  width auto
  background-color white
  color black
  font-weight 400
  font-size $fs-little
  &--large
    display flex
    font-size $fs-body
  .chip__status
    display flex
    justify-content flex-start
    align-items center
    width 15px
  .chip__value
    display flex
    flex 2
    justify-content center
    align-items center
    &--large
      justify-content start
      padding-left 5px
  .check-circle
    width 10px
    height @width
    border-radius 50%
    background-color $silver
    transition all 0.3s ease
    &--selected
      background-color black
</style>
