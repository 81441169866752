<template>
  <div v-on-clickaway="clickAwayFromSelect"
       :class="OSelectClass"
       class="select-field">
    <div :class="valueClass"
         class="value"
         @click="toggleOptionList">
      <span>
        {{ selectedOption && selectedOption.label }}
        <span v-if="showValue && selectedOption">
          ({{ selectedOption.value }})
        </span>
      </span>
      <span :class="selectIconClass"
            class="select__icon">
        <ChevronPicto color="white"
                      class="chevron" />
      </span>
    </div>
    <ul v-show="showOptionList"
        :class="listClass"
        class="list">
      <div v-show="search"
           class="search-input">
        <input ref="searchInput"
               v-model="searchInput"
               class="input"
               type="text"
               @keyup.enter="addOption"
               @input="searchTerms">
        <div v-if="customInput"
             class="add-button"
             @click="addOption">
          <q-icon size="20px"
                  name="add" />
        </div>
      </div>
      <div class="list-entries">
        <li v-for="(opt, idx) in optionsDisplayed"
            :key="idx"
            :class="listItemClass"
            :style="big ? 'font-size: 2rem;': ''"
            class="list__item"
            @click="selectOption(opt)">
          {{ opt.label }} <span v-if="showValue">
            ({{ opt.value }})
          </span>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import ChevronPicto from '@/app/components/ui/pictos/chevron.vue';
import Fuse from 'fuse.js';

export default {
  name: 'SelectField',
  components: {
    ChevronPicto,
  },
  mixins: [clickaway],
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [{ label: '', value: null }],
    },
    search: {
      type: Boolean,
      default: false,
    },
    customInput: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'black',
      validator: value => ['black', 'white'].includes(value),
    },
    big: {
      type: Boolean,
      default: false,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
      showOptionList: false,
      optionsDisplayed: [],
      searchInput: '',
    };
  },
  computed: {
    selectIconClass() {
      return {
        'select__icon--close': this.showOptionList,
      };
    },
    valueClass() {
      return {
        'value--empty': !this.selectedOption,
      };
    },
    OSelectClass() {
      return {
        'select-field--black': this.color === 'black',
        'select-field--white': this.color === 'white',
      };
    },
    listClass() {
      return {
        'list--black': this.color === 'black',
        'list--white': this.color === 'white',
      };
    },
    listItemClass() {
      return {
        'list__item--black': this.color === 'black',
        'list__item--white': this.color === 'white',
      };
    },
  },
  watch: {
    searchInput(val) {
      if (!val || !val.length) this.optionsDisplayed = this.options;
    },
    options: {
      handler(val) {
        if (!this.value) {
          this.selectedOption = null;
          this.optionsDisplayed = val;
          return;
        }
        this.selectedOption = val.find(o => o.value === this.value);
        this.optionsDisplayed = val;
      },
      deep: true,
    },
    value() {
      this.selectedOption = this.options.find(o => o.value === this.value);
    },
  },
  created() {
    if (this.value) this.selectedOption = this.options.find(o => o.value === this.value);
    this.optionsDisplayed = this.options;
  },
  methods: {
    clickAwayFromSelect() {
      this.showOptionList = false;
    },
    toggleOptionList() {
      this.showOptionList = !this.showOptionList;
      if (this.search)
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
    },
    selectOption(option) {
      this.selectedOption = option;
      this.showOptionList = false;
      this.$emit('input', option.value);
    },
    searchTerms() {
      if (!this.searchInput.trim().length) this.optionsDisplayed = this.options;
      const fuse = new Fuse(this.options, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['label', 'value'],
      });
      const found = fuse.search(this.searchInput.trim());
      this.optionsDisplayed = found;
    },
    addOption() {
      const val = this.searchInput.trim();
      if (!val || !val.length) return;
      this.showOptionList = false;
      this.searchInput = '';
      this.$emit('input', val);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.select-field
  position relative
  border-radius $border-radius-smooth
  user-select none
  &--black
    background-color black
    color white
  &--white
    border 1px solid white
    background-color black
    color white
  .value
    z-index 2
    display flex
    padding 8px
    .select__icon
      margin-left auto
      width 15px
      cursor pointer
      transition transform 0.3s ease
      &--close
        transform rotate(180deg)
    &--empty
      height 30px
  .list
    position absolute
    top 100%
    left 0
    z-index 10
    display flex
    flex-direction column
    overflow-y auto
    margin-bottom 30px
    max-height 250px
    width 100%
    border-bottom solid 2px $silver
    border-radius $border-radius-smooth
    border-top-left-radius 0px
    border-top-right-radius 0px
    box-shadow $shadow-elevation-3
    user-select none
    &--white
      background-color white
      color black
    &--black
      background-color black
    .list-entries
      flex-grow 1
      overflow-y auto
      .list__item
        padding $space-1
        font-size $fs-h3
        cursor pointer
        &:last-child
          border-bottom none
          &:hover
            border-radius $border-radius-smooth
            border-top-left-radius 0px
            border-top-right-radius 0px
        &--black
          border-bottom 1px solid lighten(black, 30%)
          &:hover
            background-color lighten(black, 20%)
          .list__item:first-child
            border-top 1px solid lighten(black, 30%)
        &--white
          border-bottom 1px solid darken(white, 30%)
          &:hover
            background-color darken(white, 20%)
          .list__item:first-child
            border-top 1px solid darken(white, 30%)
    .search-input
      display flex
      justify-content center
      align-items center
      padding 5px 10px
      .input
        padding 2px
        width 85%
        border 0
        background-color $dark
        color white
        font-size 13px
      .add-button
        background-color white
        color black
        cursor pointer
</style>
